import {AddressModel} from '../../../../domain/models/Address.model';
import {BuyerInfoModel} from '../../../../domain/models/BuyerInfo.model';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {CustomFieldModel} from '../../../../domain/models/CustomField.model';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {
  AddressTarget,
  CustomerDetailsFormOverrides,
  CustomerDetailsFormSection,
  CustomerDetailsFormValues,
  FormFieldTarget,
  FormHeaderId,
  FullNameTarget,
} from '../../../../types/app.types';
import {getAdditionalInfoFormInitialState} from '../AdditionalInfoForm/AdditionalInfoForm.utils';
import {getAddressFormInitialState} from '../AddressForm/addressForm.utils';
import {getContactFormInitialState} from '../ContactForm/contactForm.utils';
import {getEmailFormInitialState} from '../EmailForm/emailForm.utils';
import {DEFAULT_COUNTRY} from '../../constants';
import {ILocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {
  getDeliveryCountriesOverrides,
  getExcludeGoogleAutoCompleteOverrides,
  getHideCountryOverride,
  getSettingsOverrides,
  getSettingsOverridesForCustomizableFields,
  mergeOverrides,
} from '../form.utils';

export function getCustomerDetailsFormInitialState({
  checkoutSettings,
  checkoutComposerEnabled,
  address,
  isGroo,
  shouldFilterByShippingCountries,
  buyerInfo,
  contact,
  country = DEFAULT_COUNTRY,
  customField,
}: {
  checkoutSettings: CheckoutSettingsModel;
  checkoutComposerEnabled: boolean;
  address?: AddressModel;
  isGroo?: boolean;
  shouldFilterByShippingCountries?: boolean;
  initialAddress?: AddressModel;
  buyerInfo?: BuyerInfoModel;
  contact?: ContactModel;
  country?: string;
  customField?: CustomFieldModel;
}): CustomerDetailsFormValues {
  return {
    ...(buyerInfo ? getEmailFormInitialState(buyerInfo) : {}),
    ...getContactFormInitialState({
      checkoutSettings,
      contact,
      country,
      checkoutComposerEnabled,
    }),
    ...getAdditionalInfoFormInitialState(customField),
    ...getAddressFormInitialState(
      checkoutSettings,
      checkoutComposerEnabled,
      address,
      isGroo,
      shouldFilterByShippingCountries
    ),
  };
}

function getLabelsOverrides({
  isDonationsTheme,
  localeKeys,
}: {
  isDonationsTheme?: boolean;
  localeKeys?: ILocaleKeys;
}): CustomerDetailsFormOverrides {
  return localeKeys
    ? {
        [FormFieldTarget.email]: {
          label: isDonationsTheme
            ? localeKeys.checkout.donations.address_information.donor_email_label()
            : localeKeys.checkout.address_information.email_label(),
        },
      }
    : {};
}

function getHeadersOverrides({
  isShippingFlow,
  isMember,
}: {
  isShippingFlow: boolean;
  isMember: boolean;
}): CustomerDetailsFormOverrides {
  return {
    [FormHeaderId.customer_details_header]: {
      hidden: true,
    },
    [FormHeaderId.shipping_details_header]: {
      hidden: !isShippingFlow || isMember,
    },
  };
}

function getCheckoutFlowsOverrides({
  isShippingFlow,
  isMember,
  formSections,
}: {
  isShippingFlow: boolean;
  isMember: boolean;
  formSections: CustomerDetailsFormSection[];
}): CustomerDetailsFormOverrides {
  /*istanbul ignore next*/
  const additionalInfoSectionOverride = !formSections.includes(CustomerDetailsFormSection.additionalInfo)
    ? {[FormFieldTarget.additional_info_1]: {hidden: true, required: false}}
    : {};

  /*istanbul ignore next*/
  const contactSectionOverride = !formSections.includes(CustomerDetailsFormSection.contact)
    ? {
        [FormFieldTarget.full_name]: {
          [FullNameTarget.first_name]: {hidden: true, required: false},
          [FullNameTarget.last_name]: {hidden: true, required: false},
        },
        [FormFieldTarget.phone]: {hidden: true, required: false},
        [FormFieldTarget.company_name]: {hidden: true, required: false},
      }
    : {};

  const emailSectionOverride =
    isMember || !formSections.includes(CustomerDetailsFormSection.email)
      ? {[FormFieldTarget.email]: {hidden: true, required: false}}
      : {};

  const addressSectionOverride =
    !isShippingFlow || /*istanbul ignore next*/ !formSections.includes(CustomerDetailsFormSection.address)
      ? {
          [FormFieldTarget.address]: {
            [AddressTarget.address_line]: {hidden: true, required: false},
            [AddressTarget.address_line_2]: {hidden: true, required: false},
            [AddressTarget.street_name]: {hidden: true, required: false},
            [AddressTarget.street_number]: {hidden: true, required: false},
            [AddressTarget.city]: {hidden: true, required: false},
            [AddressTarget.country]: {hidden: true, required: false},
            [AddressTarget.subdivision]: {hidden: true, required: false},
            [AddressTarget.postal_code]: {hidden: true, required: false},
          },
        }
      : {};

  return mergeOverrides(
    emailSectionOverride,
    contactSectionOverride,
    addressSectionOverride,
    additionalInfoSectionOverride
  );
}

export function getCustomerDetailsFormOverrides({
  isDonationsTheme,
  localeKeys,
  checkoutSettings,
  isGroo,
  excludeGoogleAutoComplete,
  shouldFilterByShippingCountries,
  checkoutComposerEnabled,
  isShippingFlow,
  isMember,
  formSections,
}: {
  isDonationsTheme?: boolean;
  localeKeys?: ILocaleKeys;
  checkoutSettings: CheckoutSettingsModel;
  isGroo: boolean;
  excludeGoogleAutoComplete: boolean;
  shouldFilterByShippingCountries: boolean;
  checkoutComposerEnabled: boolean;
  isShippingFlow: boolean;
  isMember: boolean;
  formSections?: CustomerDetailsFormSection[];
}): CustomerDetailsFormOverrides {
  const hideCountryOverride = isGroo ? /*istanbul ignore next*/ getHideCountryOverride() : {};
  const deliveryCountriesOverrides = getDeliveryCountriesOverrides(checkoutSettings.enabledDeliveryCountries);

  return mergeOverrides(
    getLabelsOverrides({isDonationsTheme, localeKeys}),
    getSettingsOverrides({checkoutSettings}),
    !checkoutComposerEnabled ? getSettingsOverridesForCustomizableFields(checkoutSettings) : {},
    shouldFilterByShippingCountries ? deliveryCountriesOverrides : hideCountryOverride,
    getHeadersOverrides({isShippingFlow, isMember}),
    getExcludeGoogleAutoCompleteOverrides({excludeGoogleAutoComplete}),
    getCheckoutFlowsOverrides({
      isShippingFlow,
      isMember,
      formSections: formSections ?? [
        CustomerDetailsFormSection.email,
        CustomerDetailsFormSection.contact,
        CustomerDetailsFormSection.address,
        CustomerDetailsFormSection.additionalInfo,
      ],
    })
  );
}
