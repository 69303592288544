import React, {ComponentType, createContext, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {
  getContactDetailsFromContactFormValues,
  getContactFormCurrentStateWithUpdatedCountry,
  getContactFormInitialState,
} from '../../../../Form/ContactForm/contactForm.utils';
import {getEmailFormInitialState, getEmailFromEmailFormValues} from '../../../../Form/EmailForm/emailForm.utils';
import {
  getAddressFormInitialState,
  getAddressFromAddressFormValues,
} from '../../../../Form/AddressForm/addressForm.utils';
import {FormInstanceData, useFormInstance} from '../../../../Form/useFormInstance';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {ApiAddressFragment, FullAddressContactDetailsFragment} from '../../../../../../gql/graphql';
import {
  getAdditionalInfoFormInitialState,
  getCustomFieldFromAdditionalInfoFormValues,
} from '../../../../Form/AdditionalInfoForm/AdditionalInfoForm.utils';
import {ADD_NEW_ADDRESS_ID, SPECS} from '../../../../constants';
import {ContactModel} from '../../../../../../domain/models/Contact.model';
import {isContactDefined} from '../../../../../../domain/utils/isContactDefined';
import {isShippingDestinationSignificant} from '../../../../../../domain/utils/isShippingDestinationSignificant';
import {AddressModel} from '../../../../../../domain/models/Address.model';
import {getExtendedFieldsFormInitialState} from '../../../../Form/ExtendedFieldsForm/ExtendedFieldsForm.utils';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {FormValues} from '@wix/form-fields';
import {getCustomerDetailsFormInitialState} from '../../../../Form/CustomerDetailsForm/CustomerDetailsForm.utils';

export type CustomerDetailsDataContextType = {
  emailForm: FormInstanceData;
  contactForm: FormInstanceData;
  additionalInfoForm: FormInstanceData;
  extendedFieldsForm?: FormInstanceData;
  addressForm: FormInstanceData;
  customerDetailsForm: FormInstanceData;
  areFormsValid: () => Promise<boolean>;
  updateContactCountry: (country: string) => void;
  getCustomerDetailsForSubmit: () => {
    contactDetails?: FullAddressContactDetailsFragment;
    email?: string;
    extendedFieldsValue?: any;
    customFieldValue?: string;
    shippingAddress?: ApiAddressFragment;
  };
  contactCountry?: string;
};

export const CustomerDetailsDataContext = createContext({} as CustomerDetailsDataContextType);

export function withCustomerDetailsData<T extends object>(Component: ComponentType<T>) {
  /* eslint-disable-next-line sonarjs/cognitive-complexity */
  return function Wrapper(props: T) {
    const {experiments} = useExperiments();
    const {
      checkoutStore: {checkout, isShippingFlow},
      checkoutSettingsStore: {checkoutSettings, checkoutComposerEnabled, isGroo},
      memberStore: {isMember, defaultAddressId, addressesInfo},
    } = useControllerProps();

    const useConsolidatedCustomerDetailsForm = experiments.enabled(SPECS.UseSingleCustomerDetailsForm);
    const shouldFilterByShippingCountries = experiments.enabled(SPECS.FilterCountriesByShippingCountries);

    function getDefaultAddressFromAddresses(): AddressWithContactModel | undefined {
      return addressesInfo?.addresses.find((address) => address.addressesServiceId === defaultAddressId);
    }

    function getInitialContact(): ContactModel | undefined {
      if (isShippingDestinationSignificant(checkout.shippingDestination, isShippingFlow)) {
        return checkout.shippingDestination?.contact;
      }

      if (!isShippingFlow && isContactDefined(checkout.billingInfo?.contact)) {
        return checkout.billingInfo?.contact;
      }

      if (!isMember) {
        return checkout.shippingDestination?.contact;
      }

      return getDefaultAddressFromAddresses()?.contact;
    }

    function getInitialAddress(): AddressModel | undefined {
      if (!isMember || isShippingDestinationSignificant(checkout.shippingDestination, isShippingFlow)) {
        return checkout.shippingDestination?.address;
      }

      return getDefaultAddressFromAddresses()?.address;
    }

    const {editMode, selectedAddressesService, selectedAddressesServiceId} = useMemberDetailsData();

    const emailForm = useFormInstance(getEmailFormInitialState(checkout.buyerInfo));
    const contactForm = useFormInstance(() =>
      getContactFormInitialState({
        checkoutSettings,
        contact: getInitialContact(),
        country: checkout.shippingDestination?.address?.country,
        checkoutComposerEnabled,
      })
    );
    const extendedFieldsForm = useFormInstance(() => getExtendedFieldsFormInitialState(checkout.extendedFields));
    const additionalInfoForm = useFormInstance(getAdditionalInfoFormInitialState(checkout.customField));
    const addressForm = useFormInstance(() =>
      getAddressFormInitialState(
        checkoutSettings,
        checkoutComposerEnabled,
        getInitialAddress(),
        isGroo,
        shouldFilterByShippingCountries
      )
    );

    const customerDetailsForm = useFormInstance(
      () =>
        getCustomerDetailsFormInitialState({
          checkoutSettings,
          checkoutComposerEnabled,
          address: getInitialAddress(),
          isGroo,
          shouldFilterByShippingCountries,
          buyerInfo: checkout.buyerInfo,
          contact: getInitialContact(),
          customField: checkout.customField,
          country: checkout.shippingDestination?.address?.country,
        }) as FormValues
    );

    const forms = useConsolidatedCustomerDetailsForm
      ? [customerDetailsForm]
      : [
          contactForm,
          ...(checkoutComposerEnabled ? [extendedFieldsForm] : []),
          additionalInfoForm,
          ...(!isMember ? [emailForm] : []),
          ...(isShippingFlow ? [addressForm] : []),
        ];

    const areFormsValid = async () => {
      const areFormsValidArr = await Promise.all(forms.map(({isValid}) => isValid()));

      return !areFormsValidArr.includes(false);
    };

    const areFormsRendered = () => forms.some((form) => form.isRendered());

    const getCustomerDetailsForSubmit = () => {
      const addressWithContact = areFormsRendered()
        ? {
            contactDetails: getContactDetailsFromContactFormValues(
              useConsolidatedCustomerDetailsForm ? customerDetailsForm.data.formValues : contactForm.data.formValues,
              checkoutSettings,
              checkoutComposerEnabled
            ),
            shippingAddress: isShippingFlow
              ? getAddressFromAddressFormValues(
                  checkoutSettings,
                  useConsolidatedCustomerDetailsForm
                    ? customerDetailsForm.data.formValues
                    : addressForm.data.formValues,
                  checkoutComposerEnabled
                )
              : undefined,
          }
        : {};
      return {
        ...addressWithContact,
        email: !isMember
          ? getEmailFromEmailFormValues(
              useConsolidatedCustomerDetailsForm ? customerDetailsForm.data.formValues : emailForm.data.formValues
            )
          : checkout.buyerInfo.email,
        ...(checkoutComposerEnabled ? {extendedFieldsValue: extendedFieldsForm.data.formValues} : {}),
        customFieldValue: checkoutSettings.customField.show
          ? getCustomFieldFromAdditionalInfoFormValues(
              useConsolidatedCustomerDetailsForm
                ? customerDetailsForm.data.formValues
                : additionalInfoForm.data.formValues
            )
          : undefined,
      };
    };

    const initForm = (shippingDestination: AddressWithContactModel) => {
      if (useConsolidatedCustomerDetailsForm) {
        customerDetailsForm.data.setFormValues(
          getCustomerDetailsFormInitialState({
            checkoutSettings,
            checkoutComposerEnabled,
            address: shippingDestination?.address,
            isGroo: undefined,
            shouldFilterByShippingCountries,
            contact: shippingDestination?.contact,
            customField: checkout.customField,
            country: shippingDestination?.address?.country,
          }) as FormValues
        );
      } else {
        contactForm.data.setFormValues(
          getContactFormInitialState({
            checkoutSettings,
            contact: shippingDestination?.contact,
            country: shippingDestination?.address?.country,
            checkoutComposerEnabled,
          })
        );
        additionalInfoForm.data.setFormValues(getAdditionalInfoFormInitialState(checkout.customField));
        addressForm.data.setFormValues(
          getAddressFormInitialState(
            checkoutSettings,
            checkoutComposerEnabled,
            shippingDestination?.address,
            undefined,
            shouldFilterByShippingCountries
          )
        );
      }
    };

    const [contactCountry, setContactCountry] = useState(checkout.shippingDestination?.address?.country);

    const updateContactCountry = (country: string) => {
      setContactCountry(country);

      if (useConsolidatedCustomerDetailsForm) {
        customerDetailsForm.data.setFormValues(
          getContactFormCurrentStateWithUpdatedCountry({
            contactFormValues: customerDetailsForm.data.formValues,
            country,
          })
        );
      } else {
        contactForm.data.setFormValues(
          getContactFormCurrentStateWithUpdatedCountry({
            contactFormValues: contactForm.data.formValues,
            country,
          })
        );
      }
    };

    useEffect(
      () => {
        if (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) {
          initForm(new AddressWithContactModel({addressesServiceId: ADD_NEW_ADDRESS_ID}));
        } else if (editMode) {
          initForm(selectedAddressesService!);
        }
      },
      /* eslint-disable react-hooks/exhaustive-deps*/ [editMode, selectedAddressesServiceId]
    );

    return (
      <CustomerDetailsDataContext.Provider
        value={{
          contactForm,
          emailForm,
          ...(checkoutComposerEnabled ? {extendedFieldsForm} : {}),
          additionalInfoForm,
          addressForm,
          customerDetailsForm,
          areFormsValid,
          getCustomerDetailsForSubmit,
          updateContactCountry,
          contactCountry,
        }}>
        <Component {...props} />
      </CustomerDetailsDataContext.Provider>
    );
  };
}

export function useCustomerDetailsData() {
  const {
    contactForm,
    addressForm,
    additionalInfoForm,
    emailForm,
    customerDetailsForm,
    extendedFieldsForm,
    areFormsValid,
    getCustomerDetailsForSubmit,
    updateContactCountry,
    contactCountry,
  } = useContext(CustomerDetailsDataContext);

  return {
    contactFormData: contactForm.data,
    addressFormData: addressForm.data,
    additionalInfoFormData: additionalInfoForm.data,
    emailFormData: emailForm.data,
    customerDetailsFormData: customerDetailsForm.data,
    ...(extendedFieldsForm ? {extendedFieldsFormData: extendedFieldsForm.data} : {}),
    areFormsValid,
    getCustomerDetailsForSubmit,
    updateContactCountry,
    contactCountry,
  };
}
